<script>
/**
 * name：
 * user：sa0ChunLuyu
 * date：2022-03-11 15:38:41
 */
export default {
  data() {
    return {
      jiaoyanren: [],
      task_check: false,
      choose_jiaoyan: false,
      yiqi_set_info: false,
      tpl_set_list: [],
      start_line: 0,
      image_arr: [],
      yiqitpl_set_info: [],
      yangpin_info: false,
      page_options: false,
      item_info: false,
      read_visible: false,
      readtpl_visible: false,
      yangpin_area: false,
      read_arr_list: false,
      value_mark: '',
      pdf_visible: false,
      source_record_info: false,
      pdf_url: '',
      yiqip_list: [],
      read_arr: [],
      tplread_arr_list: [],
      type: 0,
      tr_arr: ["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      options: {
        container: 'luckysheet', //luckysheet为容器id
        lang: 'zh',
        title: '',
        showinfobar: false,
        showtoolbar: false,
        showtoolbarConfig: {
          undoRedo: true, //撤销重做，注意撤消重做是两个按钮，由这一个配置决定显示还是隐藏
          font: true, // '字体'
          fontSize: true, // '字号大小'
          bold: true, // '粗体 (Ctrl+B)'
          italic: true, // '斜体 (Ctrl+I)'
          //strikethrough: true, // '删除线 (Alt+Shift+5)'
          underline: true, // '下划线 (Alt+Shift+6)'
          textColor: true, // '文本颜色'
          fillColor: true, // '单元格颜色'
          border: true, // '边框'
          image: true,
          mergeCell: true, // '合并单元格'
          horizontalAlignMode: true, // '水平对齐方式'
          verticalAlignMode: true, // '垂直对齐方式'
          print: false, // '打印'
        },
        column: 50,
        row: 50,
        showstatisticBar: false,
        enableAddRow: false,
        enableAddBackTop: false,
        userInfo: false,
        showsheetbar: false,
        cellRightClickConfig: {
          copy: true, // 复制
          copyAs: false, // 复制为
          paste: true, // 粘贴
          insertRow: true, // 插入行
          insertColumn: true, // 插入列
          deleteRow: true, // 删除选中行
          deleteColumn: false, // 删除选中列
          deleteCell: false, // 删除单元格
          hideRow: false, // 隐藏选中行和显示选中行
          hideColumn: false, // 隐藏选中列和显示选中列
          rowHeight: true, // 行高
          columnWidth: true, // 列宽
          clear: false, // 清除内容
          matrix: false, // 矩阵操作选区
          sort: false, // 排序选区
          filter: false, // 筛选选区
          chart: false, // 图表生成
          image: true, // 插入图片
          link: false, // 插入链接
          data: false, // 数据验证
          cellFormat: false // 设置单元格格式
        },
        sheetFormulaBar: false
      },
    }
  },
  mounted() {
    this.mountedDo()
  },
  methods: {
    getJiaoyanrenList() {
      // 样品管理：检测人员列表
      this.$sa0.post({
        url: this.$api('样品管理：校验人员列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.jiaoyanren = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    toShowPdf() {
      this.pdf_visible = true
    },
    toPdf() {
      if (!this.source_record_info || this.source_record_info.state === 1) {
        console.log(this.image_arr)
        console.log(this.start_line)
        this.$sa0.post({
          url: this.$api('检测流程获生成打印'),
          data: {
            content: JSON.stringify(luckysheet.toJson()),
            id: this.item_info.tpl_id,
            task_check_id: this.page_options.id,
            image_arr: this.image_arr,
            start_line: this.start_line,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              console.log(response.data.post_data.data)
              this.pdf_url = response.data.pdf_url
              this.pdf_visible = true
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      } else {
        this.toShowPdf()
      }
    },
    getOcData() {
      this.$sa0.post({
        url: this.$api('检测流程获取原始记录单配置数据'),
        data: {
          id: this.item_info.tpl_id,
          task_check_id: this.page_options.id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let list = response.data.list
            list.map((item) => {
              luckysheet.setCellValue(item.area.row[0], item.area.column[0], {
                m: `${item.data}`,
                v: `${item.data}`,
              })
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    toCheckClick() {
      this.choose_jiaoyan = true
    },
    gongshidataPush(content, start_line) {
      content.map((item) => {
        let info = {
          gsid: item.gsid,
          content: []
        }
        let z = item.content.pop();
        item.content.map((ii) => {
          let area = JSON.parse(ii.xy)
          info.content.push({
            k: ii.name,
            v: luckysheet.getCellValue(area.row[0] + start_line, area.column[0])
          })
        })
        let value = this.gongshivalue(info)
        let z_area = JSON.parse(z.xy)
        luckysheet.setCellValue(z_area.row[0] + start_line, z_area.column[0], {
          m: `${value}`,
          v: `${value}`,
        })
      })
    },
    getGongshiList() {
      this.$sa0.post({
        url: this.$api('检测流程获取检测项目公式信息'),
        data: {
          id: this.item_info.id,
          tpl_id: this.item_info.tpl_id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (response.data.info) {
              let content = JSON.parse(response.data.info.content)
              this.gongshidataPush(content, JSON.parse(response.data.area.area).row[0])
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getYiqiList(info) {
      this.$sa0.post({
        url: this.$api('获取样品设备多层选择列表'),
        data: {
          ids: info.apparatus_id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.yiqip_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    yiqiDataPush(start_line) {
      let index = 0;
      this.yiqi_set_info.content.map((item) => {
        let value = ''
        if (item.vt === 0) {
          value = item.co
        }
        if (item.vt === 1) {
          let key = `${this.read_arr[index][0]}_${item.co}}`
          index = index + 1
          value = this.read_arr_list[key].value
        }
        if (item.vt === 2) {
          let info = {
            gsid: item.co.gsid,
            content: []
          }
          item.co.content.map((ii) => {
            let v = ''
            if (Number(ii.t) === 0) {
              v = ii.v
            }
            if (Number(ii.t) === 1) {
              let key = `${this.read_arr[index][0]}_${ii.v1}}`
              index = index + 1
              v = this.read_arr_list[key].value

            }
            if (Number(ii.t) === 2) {
              let area = JSON.parse(ii.v2)
              v = luckysheet.getCellValue(area.row[0] + start_line, area.column[0])
            }
            info.content.push({
              k: ii.n,
              v: v
            })
          })
          value = this.gongshivalue(info)
        }
        let area = JSON.parse(item.xy)
        luckysheet.setCellValue(area.row[0] + start_line, area.column[0], {
          m: `${value}`,
          v: `${value}`,
        })
      })
      this.read_visible = false
    },
    yiqitplDataPush(data) {
      this.yiqitpl_set_info.map((item) => {
        let kk = `${item.mark}_${item.mark_2}`
        let area = item.area
        let value = data[kk].value
        console.log(area)
        luckysheet.setCellValue(area.row[0], area.column[0], {
          m: `${value}`,
          v: `${value}`,
        })
      })
      this.readtpl_visible = false
    },
    gongshivalue(info) {
      console.log(info)
      return l_c[`g${info.gsid}`](info.content);
    },
    getYiqivalue() {
      this.$sa0.post({
        url: this.$api('检测流程读取仪器数据'), data: {
          read_arr: this.read_arr,
          tpl_id: this.item_info.tpl_id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.read_arr_list = response.data.list
            this.yiqiDataPush(JSON.parse(response.data.area.area).row[0])
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getYiqitplvalue() {
      this.$sa0.post({
        url: this.$api('原始记录单：检测流程读取模板仪器数据'), data: {
          read_arr: this.yiqitpl_set_info,
          tpl_id: this.item_info.tpl_id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            // this.read_arr_ list = response.data.list
            this.yiqitplDataPush(response.data.list)
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getTplYiqivalue() {
      // this.$sa0.post({
      //   url: this.$api('原始记录单：获取模板仪器回填列表'), data: {
      //     tpl_id: this.item_info.tpl_id,
      //   }
      // }).then((response) => {
      //   this.$sa0.response({
      //     response: response.data,
      //     then: (response) => {
      //       this.tplread_arr_list = response.data.area
      //     },
      //     error: (response) => {
      //       layer.msg(response.message)
      //     },
      //   })
      // })
    },
    yiqisetRead() {
      let yiqi_list = []
      this.yiqi_set_info.content.map((item) => {
        if (item.vt === 1) {
          yiqi_list.push([
            this.value_mark,
            this.returnYpcontentXy(item.xy),
            item.co,
          ])
        }
        if (item.vt === 2) {
          item.co.content.map((ii) => {
            if (Number(ii.t) === 1) {
              yiqi_list.push([
                this.value_mark,
                `${this.returnYpcontentXy(item.xy)} ${item.co.name} [${ii.n}]`,
                ii.v1,
              ])
            }
          })
        }
      })
      this.read_arr = yiqi_list
      if (this.read_arr.length) {
        this.read_visible = true
      } else {
        this.getYiqivalue()
      }
    },
    getTplsetitemlist() {
      // 原始记录单：获取原始记录单模板自定义数据列表
      this.$sa0.post({
        url: this.$api('原始记录单：获取原始记录单模板自定义数据列表'), data: {
          id: this.item_info.tpl_id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.tpl_set_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    gettplYiqisetInfo() {
      this.$sa0.post({
        url: this.$api('原始记录单：获取模板仪器回填列表'), data: {
          id: this.item_info.tpl_id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let list = response.data.list.map((item) => {
              return {
                area: JSON.parse(item.range),
                name: item.name,
                mark: this.value_mark,
                mark_2: item.mark,
              }
            });
            this.yiqitpl_set_info = list
            this.readtpl_visible = true
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getYiqisetInfo() {
      this.$sa0.post({
        url: this.$api('检测流程获取仪器设置'), data: {
          id: this.item_info.tpl_id,
          item_id: this.item_info.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let content = JSON.parse(response.data.info.content);
            let area = JSON.parse(response.data.area.area);
            this.yiqi_set_info = {
              content, area
            }
            this.yiqisetRead()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getYangpinAreaTable() {
      this.$sa0.post({
        url: this.$api('检测流程获取样品区域'), data: {
          id: this.item_info.tpl_id,
          item_id: this.item_info.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.yangpin_area = response.data
            let content = JSON.parse(response.data.info.content)['data'];
            let area = JSON.parse(response.data.area.area);
            luckysheet.cancelRangeMerge({range: area})
            let start_line = area.row[0];
            let cc = [];
            content.map((line, key) => {
              cc.push([])
              line.map((item) => {
                if (item) {
                  cc[key].push({
                    "m": item.m,
                    "ct": item.ct,
                    "v": item.v
                  })
                  if (typeof item.mc !== 'undefined') {
                    if (typeof item.mc.rs !== 'undefined') {
                      let r = item.mc.r
                      let c = item.mc.c
                      let rs = item.mc.rs
                      let cs = item.mc.cs
                      luckysheet.setRangeMerge('all', {
                        range: {
                          column: [c, c + cs - 1],
                          row: [r + start_line, r + start_line + rs - 1]
                        }
                      })
                    }
                  }
                }
              })
            })
            setTimeout(() => {
              luckysheet.setRangeValue(cc, {range: area})
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    mountedDo() {
      this.page_options = this.$route.params
      this.getTaskCheckInfo()
      this.getYangpinInfo()
      this.getJiaoyanrenList()

    },
    getYangpinInfo() {
      this.$sa0.post({
        url: this.$api('检测流程获取检测样品信息'), data: {
          id: this.page_options.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.yangpin_info = response.data.info
            this.value_mark = this.yangpin_info.in_number
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    drawTpl(exportJson = false) {
      this.table_show = false;
      setTimeout(() => {
        this.table_show = true;
        setTimeout(() => {
          let options = this.options
          if (exportJson) {
            options.data = exportJson.sheets
          }
          luckysheet.destroy();
          luckysheet.create(options);
        })
      })
    },
    getCheckItemTpl(info) {
      this.$sa0.post({
        url: this.$api('获取原始记录单表格结构数据'), data: {
          id: info.tpl_id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let table = response.data.table
            this.drawTpl(table ? {sheets: [JSON.parse(table)]} : false);
            if (this.source_record_info) {
              this.type = 1
              this.pdf_url = this.source_record_info.pdf_url
              this.getExcelInfo()
            } else {
              this.type = 2
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    returnYpcontentXy(xy) {
      if (xy === '') return '';
      let xy_ = JSON.parse(xy)
      let t1 = `${this.tr_arr[xy_.column[0] + 1]}${xy_.row[0] + 1}`;
      let t2 = `${this.tr_arr[xy_.column[1] + 1]}${xy_.row[1] + 1}`;
      if (t1 === t2) {
        return t1;
      } else {
        return `${t1}-${t2}`;
      }
    },
    getExcelInfo() {
      this.$sa0.post({
        url: this.$api('检测流程获取保存的数据表'), data: {
          id: this.source_record_info.task_check_id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            console.log(this.source_record_info.task_check_id)
            let table = response.data.contents
            this.drawTpl(table ? {sheets: [JSON.parse(table).data[0]]} : false);
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getTaskCheckInfo() {
      // 开始检测检查环境
      this.$sa0.post({
        url: this.$api('开始检测检查环境'),
        data: {
          id: this.page_options.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.item_info = response.data.info;
            this.task_check = response.data.task_check;
            this.getTplsetitemlist()
            this.source_record_info = response.data.source_record;
            this.getCheckItemTpl(response.data.info)
            this.getYiqiList(response.data.info)
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getTablevalue(range) {
      return luckysheet.getCellValue(range.row[0], range.column[0])
    },
    saveExcel() {
      let save_data = [];
      save_data = this.tpl_set_list.map((item) => {
        let range = JSON.parse(item.range)
        let value = this.getTablevalue(range)
        return {
          id: item.id,
          name: item.name,
          range: item.range,
          value,
        }
      })
      this.$sa0.post({
        url: this.$api('检测流程保存检测完成'),
        data: {
          content: JSON.stringify(luckysheet.toJson()),
          id: this.item_info.tpl_id,
          task_check_id: this.page_options.id,
          url: this.pdf_url,
          save_data: save_data,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            window.location.reload()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    toCheck() {
      this.choose_jiaoyan = false
      this.$sa0.post({
        url: this.$api('原始记录单流程：提交审核'),
        data: {
          read_per: this.task_check.read_per,
          id: this.source_record_info.id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            window.location.reload()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    onCloseRead() {
      this.read_visible = false
    },
    onClosePdf() {
      this.pdf_visible = false
    },
    getcoursedata() {
      if (this.yangpin_area) {
        this.$sa0.post({
          url: this.$api('结果录入：获取检测过程结果'),
          data: {
            task_check_id: this.page_options.id,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              if (response.data.info) {
                if (response.data.info.table_content) {
                  let content = JSON.parse(response.data.info.table_content)['data'];
                  let area = JSON.parse(this.yangpin_area.area.area);
                  luckysheet.cancelRangeMerge({range: area})
                  let start_line = area.row[0];
                  this.image_arr = JSON.parse(response.data.info.image_arr)
                  this.start_line = start_line
                  let cc = [];
                  content.map((line, key) => {
                    cc.push([])
                    line.map((item) => {
                      if (item) {
                        cc[key].push({
                          "m": item.m,
                          "ct": item.ct,
                          "v": item.v
                        })
                        if (typeof item.mc !== 'undefined') {
                          if (typeof item.mc.rs !== 'undefined') {
                            let r = item.mc.r
                            let c = item.mc.c
                            let rs = item.mc.rs
                            let cs = item.mc.cs
                            luckysheet.setRangeMerge('all', {
                              range: {
                                column: [c, c + cs - 1],
                                row: [r + start_line, r + start_line + rs - 1]
                              }
                            })
                          }
                        }
                      }
                    })
                  })
                  setTimeout(() => {
                    luckysheet.setRangeValue(cc, {range: area})
                  })

                } else {
                  layer.msg('检测结果没有保存表格数据')
                }
              } else {
                layer.msg('请先配置检测结果')
              }
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      } else {
        layer.msg('请先获取样品区域')
      }
    },
  }
}
</script>
<template>
  <div>

    <a-modal v-model:visible="choose_jiaoyan" title="确认校验人" @ok="toCheck()">
      <div>
        <a-select v-model="task_check.read_per" class="w-full">
          <a-select-option :value="0">暂无</a-select-option>
          <a-select-option v-for="(jyi,jyk) in jiaoyanren" :value="jyi.id">
            {{ jyi.name }}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>

    <a-drawer
        title="报告预览"
        width="100%"
        placement="right"
        :visible="pdf_visible"
        @close="onClosePdf"
    >
      <div>
        <iframe :src="pdf_url" frameborder="0" style="width: 100%;height: calc(100vh - 140px);"></iframe>
        <div class="mt-2">
          <a-button :disabled="source_record_info && source_record_info.state !== 1" @click="saveExcel()">保存
          </a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        title="读取环境数据"
        width="100%"
        placement="right"
        :visible="readtpl_visible"
        @close="onCloseRead"
    >
      <div>
        <table class="layui-table" lay-size="sm">
          <thead>
          <tr>
            <th>获取标识</th>
            <th>获取内容</th>
            <th>获取仪器</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(readi,readk) in yiqitpl_set_info">
            <td>
              <a-input v-model="readi.mark"></a-input>
            </td>
            <td>{{ readi.name }}</td>
            <td class="yiqi_read_show">
              <a-input v-model="readi.mark_2" style="width: 500px;"></a-input>
            </td>
          </tr>
          </tbody>
        </table>
        <a-button type="primary" @click="getYiqitplvalue()">确定</a-button>
      </div>
    </a-drawer>
    <a-drawer
        title="读取仪器数据"
        width="100%"
        placement="right"
        :visible="read_visible"
        @close="onCloseRead"
    >
      <div>
        <table class="layui-table" lay-size="sm">
          <thead>
          <tr>
            <th>获取标识</th>
            <th>获取内容</th>
            <th>获取仪器</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(readi,readk) in read_arr">
            <td>
              <a-input v-model="readi[0]"></a-input>
            </td>
            <td>{{ readi[1] }}</td>
            <td class="yiqi_read_show">
              <a-input v-model="readi[2]" style="width: 500px;"></a-input>
            </td>
          </tr>
          </tbody>
        </table>
        <a-button type="primary" @click="getYiqivalue()">确定</a-button>
      </div>
    </a-drawer>
    <a-space align="start">
      <div>
        <div id="luckysheet" class="luckysheet_wrapper"></div>
      </div>
      <div>
        <div>
          <div>
            <a-space>
              <a-button :disabled="source_record_info && source_record_info.state !== 1" type="primary"
                        @click="getOcData()">获取动态信息
              </a-button>
              <a-button :disabled="source_record_info && source_record_info.state !== 1" type="primary"
                        @click="getYangpinAreaTable()">获取样品区域
              </a-button>
              <a-button :disabled="source_record_info && source_record_info.state !== 1" type="primary"
                        @click="getcoursedata()">获取检测结果
              </a-button>
            </a-space>
          </div>
          <!--          <div class="mt-2">-->
          <!--            <a-space>-->
          <!--              <b>仪器读取标识</b>-->
          <!--              <a-input :disabled="source_record_info && source_record_info.state !== 1" v-model="value_mark"></a-input>-->
          <!--              <a-button :disabled="source_record_info && source_record_info.state !== 1" type="primary"-->
          <!--                        @click="getYiqisetInfo()">读取检测仪器数据-->
          <!--              </a-button>-->
          <!--              <a-button :disabled="source_record_info && source_record_info.state !== 1" type="primary"-->
          <!--                        @click="gettplYiqisetInfo()">读取环境仪器数据-->
          <!--              </a-button>-->
          <!--            </a-space>-->
          <!--          </div>-->
          <!--          <div class="mt-2">-->
          <!--            <a-space>-->
          <!--              <a-button :disabled="source_record_info && source_record_info.state !== 1" type="primary"-->
          <!--                        @click="getGongshiList()">公式计算-->
          <!--              </a-button>-->
          <!--              <a-button :disabled="source_record_info && source_record_info.state !== 1" type="primary">结果修约</a-button>-->
          <!--            </a-space>-->
          <!--          </div>-->
          <div class="mt-2">
            <a-space>
              <a-button type="primary" @click="toPdf()">报告预览</a-button>
              <div v-if="source_record_info">
                <a-button :disabled="source_record_info.state !== 1" type="primary" @click="toCheckClick()">提交审核
                </a-button>
              </div>
            </a-space>
          </div>
        </div>
      </div>
    </a-space>
  </div>
</template>
<style>
.yiqi_read_show .ant-cascader-picker-disabled {
  color: #666666 !important;
  cursor: default !important;
  background: #ffffff !important;
}

.yiqi_read_show .ant-cascader-picker-disabled input {
  opacity: 0;
}

.yiqi_read_show .ant-cascader-picker-disabled .ant-cascader-picker-arrow {
  opacity: 0;
}
</style>
<style scoped>
.luckysheet_wrapper {
  width: 800px;
  height: calc(100vh - 140px);
}
</style>
